import React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Grid, Space } from 'antd';
import {
	AsyncDateAndTime,
	AsyncPersonalEmailAddresses,
	AsyncPersonalInformation,
} from './ExtensionSlots';
import { FeatureFlag } from './featureFlags';
import {
	AsyncEditDateAndTimeDrawer,
	AsyncEditPersonalInformationDrawer,
} from './Overlays';

export function setup(app: PiletApi) {
	(window as any).SF_piletexecuted = true;

	if (getFeatureFlagValue(FeatureFlag.EnabledRefactoredMFA)) {
		{
			app.registerExtension(
				'urn:sfextslot:sharefile-appshell:user-pilet:date-and-time-block',
				({ params }) => <AsyncDateAndTime piletApi={app} {...params} />
			);
		}

		app.registerExtension(
			'urn:sfextslot:sharefile-appshell:user-pilet:personal-information-block',
			({ params }) => <AsyncDateAndTime piletApi={app} {...params} />
		);

		app.registerDrawer(
			'urn:sfdrawer:user-pilet:edit-date-and-time',
			props => {
				return <AsyncEditDateAndTimeDrawer {...props} piletApi={app} />;
			},
			{
				drawerProps: {
					width: '400px',
				},
			}
		);

		app.registerExtension(
			'urn:sfextslot:sharefile-appshell:user-pilet:personal-email-addresses-block',
			({ params }) => <AsyncPersonalEmailAddresses piletApi={app} {...params} />
		);

		app.registerDrawer(
			'urn:sfdrawer:user-pilet:edit-personal-information',
			props => {
				return <AsyncEditPersonalInformationDrawer {...props} piletApi={app} />;
			},
			{
				drawerProps: {
					width: '400px',
				},
			}
		);

		app.registerPage('/userpiletroute', () => {
			const { lg } = Grid.useBreakpoint();
			return (
				<Space direction="vertical">
					<Space direction={lg ? 'horizontal' : 'vertical'}>
						<AsyncDateAndTime piletApi={app} />
						<AsyncPersonalInformation piletApi={app} />
					</Space>
					<AsyncPersonalEmailAddresses piletApi={app} />
				</Space>
			) as any;
		});
	}
}
