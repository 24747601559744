import React from 'react';
import { Spin } from 'antd';
import { CoreExtensionSlotParams } from './type';

const LazyDateAndTimeExtensionSlot = React.lazy(() => import('./DateAndTimeExtension'));
const LazyPersonalInformationExtensionSlot = React.lazy(
	() => import('./PersonalInformationExtension')
);
const LazyPersonalEmailAddressesExtensionSlot = React.lazy(
	() => import('./PersonalEmailAddressesExtension')
);

export const AsyncDateAndTime: React.FC<CoreExtensionSlotParams<any>> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyDateAndTimeExtensionSlot {...props} />
	</React.Suspense>
);

export const AsyncPersonalInformation: React.FC<CoreExtensionSlotParams<any>> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyPersonalInformationExtensionSlot {...props} />
	</React.Suspense>
);

export const AsyncPersonalEmailAddresses: React.FC<
	CoreExtensionSlotParams<any>
> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyPersonalEmailAddressesExtensionSlot {...props} />
	</React.Suspense>
);
