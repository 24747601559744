import React from 'react';
import { EditDateAndTimeDrawerProps } from './EditDateAndTimeDrawer';
import { EditPersonalInfoDrawerProps } from './EditPersonalInfoDrawer';

const LazyEditDateAndTimeDrawer = React.lazy(() => import('./EditDateAndTimeDrawer'));
const LazyEditPersonalInfoDrawer = React.lazy(() => import('./EditPersonalInfoDrawer'));

export const AsyncEditDateAndTimeDrawer: React.FC<EditDateAndTimeDrawerProps> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-user-group-drawer" />}>
			<LazyEditDateAndTimeDrawer {...props} />
		</React.Suspense>
	);
};

export const AsyncEditPersonalInformationDrawer: React.FC<
	EditPersonalInfoDrawerProps
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-user-group-drawer" />}>
			<LazyEditPersonalInfoDrawer {...props} />
		</React.Suspense>
	);
};
